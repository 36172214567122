import React from 'react';
import './App.css';
import { alertActions } from '_actions'
import { Login, PrivateRoute,CreatePassword,VerifyUser } from '_components';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '_helpers';
import { checkLoggedInUser } from "_services";
import { ProgressSpinner } from 'primereact/progressspinner';
import { SpinnerService } from '../_services/spinner.service';


class App extends React.Component {
	state:any;
	constructor(props: any) {
		super(props);
		history.listen((location, action) => {
			// clear alert on location change
			alertActions.clear();
		});
		this.state={
			spinner:false
		};
		props.checkLoggedInUser();
		SpinnerService.getMessage().subscribe((data: any)=>{
			try {	
				this.setState({
					spinner: data
				});
			} catch (error) {
				
			}
		});
	}

	render() {
		const { authentication }: any = this.props;
		return (
			<div>
				<div className={this.state.spinner?'app-loader':''}>{this.state.spinner?<ProgressSpinner className="app-spinner"/>:''}</div>
				<Router history={history}>
					{authentication.loggedIn ?
						(<Switch>
							<PrivateRoute path="/" parentProps={this.props} />
						</Switch>) : <Switch>
							<Route path="/login" render={() => <Login />} />
							<Route exact path="/create-password" render={() => <CreatePassword  />} />
							<Route exact path="/create-password/:otp" render={() => <CreatePassword  />} />
							<Route exact path="/verify/:token" render={() => <VerifyUser  />} />
							<Redirect to="/login" />
						</Switch>}
				</Router>
			</div>
		);
	}
}

function mapState(state: any) {
	const { alert, authentication } = state;
	return { alert, authentication };
}
const connectedApp = connect(mapState, { checkLoggedInUser: checkLoggedInUser })(App);
export { connectedApp as App };
