import { createStore, applyMiddleware, Middleware } from 'redux';

import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from 'redux-logger';
import rootReducer from '_reducers';

const loggerMiddleware: Middleware<{}, any, any> = (storeAPI) => (next) => (action) => {
  createLogger({})
  return next(action);
};
export const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    ))
);
